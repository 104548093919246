import React from 'react';
import CoursesJoin from '../CoursesJoin';
import CoursesRegistrationView from '../CoursesRegistrationView';
import withRegistration from '../withRegistration';

const RegistrationOpen = withRegistration(
  ({ id, email }) => (
    <CoursesJoin courseId={id} email={email} />
  ),
  CoursesRegistrationView,
  { text: 'joinCourse' },
);

export default RegistrationOpen;
